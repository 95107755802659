<template>
  <div class="resume-form-item" v-if="item.status === 'error'">
    <div class="resume-form-item_resume-info">
      <div class="icon-wrap">
        <svg class="icon" fill="none" height="24" viewBox="0 0 24 24" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.5259 7.80445H18.2562C18.371 7.80445 18.4611 7.71306 18.4611 7.59675C18.4611 7.22706 18.3013 6.87814 18.0225 6.64552L14.8948 4.00367C14.6571 3.80429 14.3619 3.69629 14.0504 3.69629C13.8823 3.69629 13.7471 3.83337 13.7471 4.00367V7.01521C13.7471 7.45137 14.0955 7.80445 14.5259 7.80445Z"
            fill="#111111"/>
          <path
            d="M12.4615 7.01546V3.69238H7.31174C6.59028 3.69238 6 4.29054 6 5.02161V18.9785C6 19.7096 6.59028 20.3078 7.31174 20.3078H17.1498C17.8713 20.3078 18.4615 19.7096 18.4615 18.9785V9.11546H14.5263C13.1538 9.11546 12.4615 8.53854 12.4615 7.01546Z"
            fill="#111111"/>
        </svg>
      </div>
      <div class="info-wrap">
        <span class="resume-file-name">{{ item.file.name }}</span>
        <span class="resume-size">{{ (item.file.size / 1024 / 1024).toPrecision(1) }} MB</span>
      </div>
    </div>
    <div class="resume-form-item_buttons">
      <div class="cancel-button" @click="$emit('re-upload-item', item)">Повторить</div>
      <div class="remove-button" @click="$emit('close-icon-click', item)">Удалить</div>
    </div>
  </div>
  <div class="resume-form-item" v-else>
    <Preloader v-model="form.preloader" :backdrop="true"/>
    <v-skeleton-loader :boilerplate="true" :loading="loading">
      <template #default>
        <div class="resume-form-item_header">
          <div class="form">
            <SkeletonField title="Имя"/>
            <SkeletonField title="Фамилия"/>
          </div>
          <div class="resume-form-item_photo"></div>
        </div>
        <div class="form">
          <SkeletonField title="Телефон"/>
          <SkeletonField title="Должность"/>
          <SkeletonField title="Email"/>
          <SkeletonField title="Пол"/>
          <SkeletonField title="Комментарии"/>
          <SkeletonField title="Ваша оценка"/>
        </div>
      </template>
      <template #default>
        <div class="resume-form-item_close" @click="$emit('close-icon-click', item)" v-if="closeIcon">
          <div class="icon icon-close"></div>
        </div>
        <div class="resume-form-item_header">
          <div class="form">
            <v-text-field
              v-model="form.data.name"
              name="name"
              persistent-placeholder
              label="Имя"
            />
            <v-text-field v-model="form.data.surname" name="surname" label="Фамилия" persistent-placeholder/>
          </div>
          <div class="resume-form-item_header_right">
            <div class="resume-form-item_photo pointer" @click="addPhoto">
              <img
                v-if="form.data.image && form.data.image.url"
                :src="form.data.image.url"
                alt="candidate image"
              >
            </div>
            <div class="resume-form-item_resume-info" @click="$emit('resume-click', item)" v-if="resumeIcon">
              <div class="icon-wrap">
                <svg class="icon" fill="none" height="24" viewBox="0 0 24 24" width="24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.5259 7.80445H18.2562C18.371 7.80445 18.4611 7.71306 18.4611 7.59675C18.4611 7.22706 18.3013 6.87814 18.0225 6.64552L14.8948 4.00367C14.6571 3.80429 14.3619 3.69629 14.0504 3.69629C13.8823 3.69629 13.7471 3.83337 13.7471 4.00367V7.01521C13.7471 7.45137 14.0955 7.80445 14.5259 7.80445Z"
                    fill="#111111"/>
                  <path
                    d="M12.4615 7.01546V3.69238H7.31174C6.59028 3.69238 6 4.29054 6 5.02161V18.9785C6 19.7096 6.59028 20.3078 7.31174 20.3078H17.1498C17.8713 20.3078 18.4615 19.7096 18.4615 18.9785V9.11546H14.5263C13.1538 9.11546 12.4615 8.53854 12.4615 7.01546Z"
                    fill="#111111"/>
                </svg>
              </div>
              <div class="info-wrap">
                <span class="resume-size">{{ (item.file.size / 1024 / 1024).toPrecision(1) }} MB</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form">
          <v-text-field
            v-model="form.data.phone"
            :mask="['+# (###) ###-##-##', '+### (##) ###-##-##']"
            name="phone"
            label="Телефон"
            persistent-placeholder
          />
          <v-text-field persistent-placeholder v-model="form.data.position" name="position" label="Должность" list="positions-candidates"/>
          <v-text-field v-model="form.data.email" label="Email" persistent-placeholder/>
          <v-select v-model="form.data.gender" name="gender" label="Пол"
                    item-value="id"
                    item-text="title"
                    append-icon=""
                    :items="[{id: '0', title: 'Не выбран'}, {id: '1', title: 'Мужской'}, {id: '2', title:'Женский'}]"
                    persistent-placeholder
          />
        </div>
      </template>
    </v-skeleton-loader>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader.vue';
import PopoverList from '@/components/PopoverList.vue';
import ChangeEventRating from '@/views/event/ChangeEventRating.vue';

export default {
  name: 'FormResumeItem',
  components: {
    PopoverList,
    Preloader,
    ChangeEventRating
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    closeIcon: {
      type: Boolean,
      default: true,
    },
    resumeIcon: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'on-mounted',
    'close-icon-click',
    're-upload-item',
    'resume-click',
  ],
  methods: {
    addPhoto() {
      const inputFile = document.createElement('input');
      inputFile.setAttribute('type', 'file');
      inputFile.setAttribute('accept', 'image/*');
      inputFile.addEventListener('change', (e) => {
        if (e.target.files.length) {
          this.form.data.image = { url: URL.createObjectURL(e.target.files[0]) };
          this.form.preloader = true;
          this.$server.uploadFile(e.target.files[0], { type: 'candidate' })
            .then((dataResponse) => {
              if (dataResponse.success) this.form.data.image = dataResponse.response;
            })
            .finally(() => {
              this.form.preloader = false;
            });
        }
      });
      inputFile.click();
    },
    setRate(rate) {
      if (this.eventData.rate === rate) {
        this.eventData.rate = null;
        return;
      }
      this.eventData.rate = rate;
      this.$forceUpdate();
    },
    classRateIconFromPosition(position) {
      if (!Number(this.eventData.rate) || position > this.eventData.rate) return 'icon-rating-line';
      return 'icon-rating';
    },
  },
  computed: {
    loading() {
      return !this.form.data.resume.url;
    },
    form() {
      return this.item.form;
    },
    eventData() {
      return this.item.event;
    },
  },
  mounted() {
    this.$emit('on-mounted', this);
  },
};
</script>

<style lang="scss">
.resume-form-item {
  position: relative;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  &_close {
    color: rgba(var(--page-color-main-rgb), .25);
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  &_header {
    margin-bottom: 24px;
    display: flex;

    &_right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .form {
      width: 100%;
      margin-right: 24px;
    }
  }

  &_photo {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    background: #F4F3F2;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &.pointer {
      cursor: pointer;
    }
  }

  &_resume-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .icon-wrap {
      padding: 3px;
      margin-bottom: 4px;
      background: #FFFFFF;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
      border-radius: 6px;

      .icon {
        width: 24px;
        height: 24px;
      }
    }

    .resume-size {
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: rgba(var(--page-color-main-rgb), .5);
    }

    .resume-file-name {
      font-size: 14px;
      line-height: 20px;
      color: var(--color-blue);
    }
  }

  &.error {
    .resume-form-item_resume-info {
      flex-direction: row;
      margin-bottom: 24px;
    }

    .icon-wrap {
      margin-bottom: 0;
    }

    .info-wrap {
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .resume-form-item_buttons {
    & > * {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .candidate-init-rating {
    &_title {
      font-size: 14px;
      line-height: 16px;
      color: rgba(var(--page-color-main-rgb), .5);
      margin-bottom: 8px;
    }

    .icon {
      font-size: 28px;
      color: rgba(0, 0, 0, .25);
      cursor: pointer;

      &.green_text {
        color: #8ABCAE;
      }

      &.yellow_text {
        color: #BA9A35;
      }

      &.red_text {
        color: #B8705E;
      }
    }
  }
}

.not-phone-button {
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: var(--page-color-main);
  font-family: var(--font-family);
  cursor: pointer;
}

</style>
