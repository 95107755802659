<template>
  <Modal
    ref="modal"
    @backdrop-click="modalsWithConflictingCandidates[0].cancel()"
    @close-icon-click="modalsWithConflictingCandidates[0].cancel()"
    title="Кандидат с таким номером телефона уже существует"
    :vertical-btns="false"
  >
    <div class="conflicting-candidates" v-if="modalsWithConflictingCandidates[0]">
      <div class="conflicting-candidate">
        <CandidateMedia :candidate="modalsWithConflictingCandidates[0].old"/>
        <div class="candidate-status">Существующий</div>
        <div class="conflicting-candidate_title">
          {{ modalsWithConflictingCandidates[0].old.name }} {{ modalsWithConflictingCandidates[0].old.surname }}
        </div>
        <div class="conflicting-candidate_phone">{{ convertPhoneStr(modalsWithConflictingCandidates[0].old.phone) }}</div>
      </div>
      <div class="conflicting-candidate">
        <CandidateMedia :candidate="modalsWithConflictingCandidates[0].new"/>
        <div class="candidate-status">Новый</div>
        <div class="conflicting-candidate_title">
          {{ modalsWithConflictingCandidates[0].new.name }} {{ modalsWithConflictingCandidates[0].new.surname }}
        </div>
        <div class="conflicting-candidate_phone">{{ convertPhoneStr(modalsWithConflictingCandidates[0].new.phone) }}</div>
      </div>
    </div>
    <template v-if="modalsWithConflictingCandidates[0]" v-slot:actions>
      <div class="cancel-button mr-4" @click="modalsWithConflictingCandidates[0].allow">Оставить обоих</div>
      <div class="cancel-button" @click="modalsWithConflictingCandidates[0].replace">Заменить</div>
    </template>
  </Modal>
</template>

<script>
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'SendCandidates',
  components: { Preloader },
  props: {
    forms: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'start',
    'end',
  ],
  data() {
    return {
      modalsWithConflictingCandidates: [],
    };
  },
  methods: {
    submit() {
      this.$emit('start');
      Promise.all(Array.from(this.forms, (form) => this.$server.request('candidate/create', form.toResponse())))
        .then((responses) => {
          const successful = responses.filter((response) => response.success);
          const conflicts = responses.filter((response) => !response.success);

          Promise.all(Array.from(successful, ({ response }) => this.$store.dispatch('setCandidate', response)));

          return [successful, conflicts];
        })
        .then(([successful, conflicts]) => {
          if (conflicts.length === 0) return successful;

          const promises = conflicts.map((conflictData) => new Promise((conflictResolve) => {
            const request = (match) => this.$server.request('candidate/create', {
              ...conflictData.request,
              match,
            })
              .then((candidate) => {
                successful.push(candidate);
                this.$refs.modal.close();
              })
              .then(() => conflictResolve());

            const modal = {
              new: conflictData.info.request,
              old: conflictData.info.candidate,
              allow: () => {
                request(1);
              },
              replace: () => {
                request(0);
              },
              cancel: () => conflictResolve(),
            };
            this.modalsWithConflictingCandidates.push(modal);
          }));
          const duration = 100;
          promises.forEach((promise, index) => {
            promise.then(() => {
              if (this.modalsWithConflictingCandidates[index + 1]) {
                setTimeout(() => {
                  this.$refs.modal.open();
                }, duration);
              }
            });
          });
          // this.modalsWithConflictingCandidates[0].component.open(duration);
          setTimeout(() => {
            this.$refs.modal.open();
          }, duration);
          return Promise.all(promises)
            .then(() => successful);
        })
        .then((candidates) => {
          this.$eventBus.emit('candidates-create', candidates);
          this.$emit('end');
        });
    },
  },
};
</script>

<style lang="scss">
.modal-content {
  .conflicting-candidates {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 9px));
    grid-column-gap: 18px;
    margin-bottom: 38px;
  }

  .conflicting-candidate {
    display: flex;
    flex-direction: column;
    align-items: center;

    .candidate-media {
      width: 56px;
      height: 56px;
      margin-bottom: 12px;
    }

    .candidate-status {
      margin-bottom: 4px;
    }

    &_title {
      width: 100%;
      margin-bottom: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &_phone {
      font-size: 13px;
      line-height: 20px;
      color: rgba(var(--page-color-main-rgb), .5);
    }
  }
}
</style>
