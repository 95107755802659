<template>
  <div class="form-panel">
    <div class="form-panel-backdrop" @click="close()"></div>
    <div class="form-panel-form">
      <FormResumeItem
        :item="item"
        :resumeIcon="false"
        @close-icon-click="close()"
        @on-mounted="setContentOffset"
      />
    </div>
    <div class="form-panel-resume">
      <Preloader v-model="preloader" :backdrop="true"/>
      <div class="form-panel-resume_content" v-html="resumeHtml.text_html"></div>
    </div>
  </div>
</template>

<script>
import FormResumeItem from '@/views/candidates/create/FormResumeItem.vue';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'PanelFormAndResume',
  components: {
    Preloader,
    FormResumeItem,
  },
  props: {
    duration: {
      type: Number,
      default: 200,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    max_offset_top: 100,
    content: null,
    resumeEl: null,
    preloader: null,
  }),
  methods: {
    open(duration = this.duration) {
      if (this.$el.classList.contains('modal-in')) return;

      this.$emit('open', this);
      if (duration) {
        this.$el.style.transition = `${duration}ms`;
        this.resumeEl.style.transition = `${duration}ms`;
        this.$el.style.opacity = 0;
        this.$el.style.display = 'block';
        setTimeout(() => {
          this.$el.style.opacity = 1;
          this.resumeEl.style.transform = 'translate(0, 0)';
        });
        setTimeout(() => {
          this.$el.removeAttribute('style');
          this.resumeEl.removeAttribute('style');
          this.$el.classList.add('modal-in');
          this.$emit('opened', this);
        }, duration);
      } else {
        this.$el.classList.add('modal-in');
        this.$emit('opened', this);
      }

      this.setContentOffset();
      window.addEventListener('resize', this.setContentOffset);

      if (this.resumeHtml.id) {
        this.setZoomResumeHtml();
      }
    },
    close(duration = this.duration) {
      if (!this.$el.classList.contains('modal-in')) return;

      this.$emit('close', this);
      if (duration) {
        this.$el.style.transition = `${duration}ms`;
        this.resumeEl.style.transition = `${duration}ms`;
        this.$el.style.opacity = 1;
        setTimeout(() => {
          this.$el.style.opacity = 0;
          this.resumeEl.style.transform = 'translate(100%, 0)';
        });
        setTimeout(() => {
          this.$el.removeAttribute('style');
          this.resumeEl.removeAttribute('style');
          this.$el.classList.remove('modal-in');
          this.$emit('closed', this);
        }, duration);
      } else {
        this.$emit('closed', this);
        this.$el.classList.remove('modal-in');
      }

      window.removeEventListener('resize', this.setContentOffset);
    },
    setContentOffset() {
      if (!this.content) return;
      this.content.removeAttribute('style');
      if (this.content.scrollHeight > document.documentElement.clientHeight - this.max_offset_top) {
        this.content.style.top = `${this.max_offset_top}px`;
      } else {
        this.content.style.top = `${Math.floor((document.documentElement.clientHeight * 0.5) - (this.content.scrollHeight * 0.5))}px`;
      }
    },
    setZoomResumeHtml() {
      if (this.resumeEl) {
        let zoom = this.resumeEl.offsetWidth / this.resumeEl.querySelector('.form-panel-resume_content').offsetWidth;
        if (zoom > 1) zoom = 1;
        this.resumeEl.querySelector('.form-panel-resume_content').style.zoom = zoom;
      }
    },
  },
  created() {
    if (!this.resumeHtml.id) {
      this.preloader = true;
      this.$store.dispatch('getResumeHtmlFromApi', this.item.form.data.resume.id)
        .then(() => {
          this.preloader = false;
          this.setZoomResumeHtml();
        });
    }
  },
  mounted() {
    this.content = this.$el.querySelector('.form-panel-form');
    this.resumeEl = this.$el.querySelector('.form-panel-resume');
  },
  computed: {
    resumeHtml() {
      return this.$store.getters.getResumeHtml(this.item.form.data.resume.id) || {};
    },
  },
};
</script>

<style scoped lang="scss">
.form-panel {
  --resume-width: 50%;

  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  overflow-y: auto;

  &.modal-in {
    display: block;

    .form-panel-resume {
      transform: translate(0, 0);
    }
  }

  &::v-deep(.preloader) {
    z-index: 102;
  }
}

.form-panel-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .7);
}

.form-panel-form {
  width: 360px;
  position: absolute;
  left: calc(50% - (var(--resume-width) / 2));
  top: 0;
  transform: translate((-50%, 0));
}

.form-panel-resume {
  width: var(--resume-width);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px 0 0 12px;
  transform: translate(100%, 0);
  overflow-y: scroll;

  &_content {
    padding: 24px;
    width: fit-content;
  }
}
</style>
