import mixin from '@/globalMixin';

export default class CandidateForm {
  constructor() {
    this.data = {
      name: '',
      surname: '',
      phone: '',
      image: {},
      position: '',
      gender: '',
      birthday: '',
      resume: {},
      hasShown: false,
      dateMenu: false,
      contacts: [
        {
          type: '0',
          value: '',
        },
        {
          type: '5',
          value: '',
        },
        {
          type: '3',
          value: '',
        },
        {
          type: '4',
          value: '',
        },
      ],
    };
    this.validValues = {};
  }

  checkForm() {
    const {
      name,
      phone,
    } = this.validValues;
    return name;
  }

  applyResultParseResume(response) {
    this.data.resume = response;

    const { info } = response;

    if (info.image && info.image.success) {
      if (!this.data.image.url) this.data.image = info.image;
    }
    this.data.name = this.data.name || info.name;
    this.data.surname = this.data.surname || info.surname;
    this.data.position = this.data.position || info.position;
    this.data.phone = this.data.phone || mixin.methods.convertPhoneStr(info.phone);
    this.data.birthday = this.data.birthday || info.born;
    this.data.gender = this.data.gender || String(info.gender);
    this.data.contacts[1].value = this.data.contacts[1].value || info.email || '';

    this.validValues.name = !!this.data.name;
    this.validValues.phone = !!this.data.phone;

    return this;
  }

  toResponse() {
    const formData = JSON.parse(JSON.stringify(this.data));
    formData.resume = formData.resume && formData.resume.id ?  formData.resume.id : null;
    formData.file = formData.resume;
    formData.image = formData.image && formData.image.id ? formData.image.id : null;
    formData.contacts = formData.contacts.filter(({ value }) => value);
    return formData;
  }
}
