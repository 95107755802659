<template>
  <div class="page"
       @dragenter.stop.prevent
       @dragover.stop.prevent
       @dragleave.stop.prevent
       @drop.stop.prevent="dropResumeFiles"
  >
    <div v-if="items.length" class="page-content">
      <v-row>
        <v-col cols="8">
          <datalist id="positions-candidates">
            <option :value="position" v-for="(position, index) in positionsCandidates" :key="index"/>
          </datalist>
          <div class="page-header">
            <div class="page-header_left">
              <div class="page-header_back-link" @click="$router.back()">
                <div class="icon icon-arrow"></div>
                <span>Назад</span>
              </div>
              <div class="page-title">
                <template v-if="loadedItems.length !== items.length">
                  <span>Загружено {{ loadedItems.length }}</span>
                  <span class="all-forms-length"> из {{ items.length }}..</span>
                </template>
                <template v-else>
                  <span>Готово</span>
                </template>
              </div>
            </div>
            <div class="page-header_right">
              <div class="resume-load-progress" v-if="notLoadedItems.length">
                <div class="resume-load-progress_text" v-if="timeLeft">
                  <span>Осталось {{ timeLeft }}</span>
                  <svg class="icon" fill="none" height="18" viewBox="0 0 18 18" width="18"
                       xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.25">
                      <path clip-rule="evenodd"
                            d="M18 9C18 4.02923 13.9694 0 9 0C4.02923 0 0 4.02923 0 9C0 13.9708 4.02923 18 9 18C13.9694 18 18 13.9708 18 9ZM8.40721 4.54237C8.90427 4.54237 9.30721 4.94531 9.30721 5.44237V8.8929L12.6918 10.0231C13.1626 10.1825 13.4151 10.6934 13.2557 11.1642C13.0963 11.635 12.5854 11.8875 12.1146 11.7281L8.11862 10.3753C7.75316 10.2516 7.50721 9.90867 7.50721 9.52283V5.44237C7.50721 4.94531 7.91016 4.54237 8.40721 4.54237Z"
                            fill="#111111"
                            fill-rule="evenodd"/>
                    </g>
                  </svg>
                </div>
                <v-progress-linear v-model="loadProgress"/>
              </div>
            </div>
          </div>
          <Field
            name="gender"
            title="Вакансия"
            type="select"
            :values="vacanciesList"
            v-model="vacancy"
            classes="select-vacancy"
          />
          <HelpInfo
            text="Остались не обработаные ошибки"
            v-if="loadedItems.length === items.length && !isAllFormsReady"
            classes="info-not-all-ready"
          />
          <v-tabs v-model="activeTab">
            <v-tab>{{ `Готовы к сохранению  ${readyItems.length}` }}</v-tab>
            <v-tab>{{ `Некорректно заполненные  ${notReadyItems.length}` }}</v-tab>
            <v-tab>{{ `Ошибка  ${errorItems.length}` }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <FormResumeItem
                v-for="(item, index) in itemsInTabReady"
                :key="index"
                :item="item"
                @close-icon-click="removeItem"
                @input="changeStatusItem(item)"
                @resume-click="openItemInPanel(item)"
              />
            </v-tab-item>
            <v-tab-item>
              <FormResumeItem
                v-for="(item, index) in itemsInTabNotReady"
                :key="index"
                :item="item"
                @close-icon-click="removeItem"
                @input="changeStatusItem(item)"
                @resume-click="openItemInPanel(item)"
              />
            </v-tab-item>
            <v-tab-item>
              <FormResumeItem
                v-for="(item, index) in itemsInTabError"
                :key="index"
                :item="item"
                @close-icon-click="removeItem"
                @re-upload-item="reUploadItem"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col cols="4">
          <div class="load-area-block" @click="selectResumeFiles('file_input_2')">
            <span class="color-blue">Выберите</span>
            или перетяните сюда файлы резюме в формате pdf, rtf, doc, docx, jpeg, jpg, png.
          </div>
          <input type="file" accept='images/jpeg, images/jpg, images/png, .pdf, .rtf, .doc, .docx'
                 multiple id="file_input_2" style="opacity: 0" @change="onChangeInput">
          <div class="button-wrap">
            <v-btn color="primary"
                   :min-width="230"
                   @click="beforeSubmit"
                   :disabled="!isAllFormsReady"
                   :loading="savingCandidate"
            >Сохранить
            </v-btn>
            <!--            <div
                          class="default-button"
                          :class="{'disabled' : !isAllFormsReady}"
                          @click="beforeSubmit"
                        >
                          <span v-if="!saveButtonPreloader">Сохранить</span>
                        </div>-->
          </div>
        </v-col>
      </v-row>

      <!--          <Tabs ref="tabs-forms" @select-tab="sortItemsByTabs">
                </Tabs>-->
    </div>
    <div v-else class="page-content pr-7">
      <div class="page-header">
        <div class="page-header_left">
          <div class="page-header_back-link" @click="$router.back()">
            <div class="icon icon-arrow"></div>
            <span>Назад</span>
          </div>
          <div class="page-title">Загрузить резюме</div>
        </div>
      </div>
      <!--        TODO временно скрыта-->
      <!--        <HelpInfo
                name="create-candidates-from-resume"
                :onceShow="true"
                :closeButton="true"
                text="Подсказка. Надо уточнить что здесь будет"
                classes="create-candidates-help-info"
              />-->
      <div class="load-area-block" @click="selectResumeFiles('file_input')">
        <span class="color-blue">Выберите</span>
        или перетяните сюда файлы резюме в формате pdf или doc
      </div>
      <input type="file" accept='images/jpeg, images/jpg, images/png, .pdf, .rtf, .doc, .docx'
             multiple id="file_input" style="opacity: 0" @change="onChangeInput">
    </div>
    <Modal ref="matching-phone-modal" classes="matching-phone-modal"
           v-if="modalMatchingPhoneItems && modalMatchingPhoneItems.matching.length">
      <div class="modal-title">
        Вы загрузили несколько резюме с одинаковым телефоном. Выберите из каждой группы, что оставить:
      </div>
      <div
        class="match-block"
        v-for="(match, index) in modalMatchingPhoneItems.matching"
        :key="index"
      >
        <div class="match-block_title">{{ match.phone }}</div>
        <div class="match-block_list">
          <ul>
            <li v-for="(item, index) in match.items" :key="index">
              <div class="match-block_item">
                <a :href="getUrlLocalFile(item.file)" target="_blank" class="match-block_item_inner">
                  <div class="icon-wrap">
                    <svg class="icon" fill="none" height="24" viewBox="0 0 24 24" width="24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.5259 7.80445H18.2562C18.371 7.80445 18.4611 7.71306 18.4611 7.59675C18.4611 7.22706 18.3013 6.87814 18.0225 6.64552L14.8948 4.00367C14.6571 3.80429 14.3619 3.69629 14.0504 3.69629C13.8823 3.69629 13.7471 3.83337 13.7471 4.00367V7.01521C13.7471 7.45137 14.0955 7.80445 14.5259 7.80445Z"
                        fill="#111111"/>
                      <path
                        d="M12.4615 7.01546V3.69238H7.31174C6.59028 3.69238 6 4.29054 6 5.02161V18.9785C6 19.7096 6.59028 20.3078 7.31174 20.3078H17.1498C17.8713 20.3078 18.4615 19.7096 18.4615 18.9785V9.11546H14.5263C13.1538 9.11546 12.4615 8.53854 12.4615 7.01546Z"
                        fill="#111111"/>
                    </svg>
                  </div>
                  <div class="info-wrap">
                    <span class="resume-file-name">{{ item.file.name }}</span>
                    <span class="resume-size">{{ (item.file.size / 1024 / 1024).toPrecision(1) }} MB</span>
                  </div>
                </a>
                <div class="match-block_item_after">
                  <Checkbox type="radio" :name="match.phone" @checked="match.selected = item"/>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="default-button"
        :class="{'disabled' : !isAllMatchingSelected}"
        @click="modalMatchingPhoneItems.apply"
      >
        Подтвердить
      </div>
    </Modal>
    <PanelFormAndResume
      ref="panel-form"
      :item="panelItem"
      v-if="panelItem"
      :key="panelItem.form.data.resume.id"
    />
    <SendCandidates
      ref="send-candidates"
      :forms="sendCandidatesForm"
      @start="startSubmit"
      @end="endSubmit"
    />
  </div>
</template>

<script>
import CandidateForm from '@/views/candidates/create/CandidateForm';
import dropFiles from '@/mixins/dropFiles';

import Preloader from '@/components/Preloader.vue';
import HelpInfo from '@/components/HelpInfo.vue';
import FormResumeItem from '@/views/candidates/create/FormResumeItem.vue';
import SendCandidates from '@/views/candidates/create/SendCandidates.vue';
import PanelFormAndResume from '@/views/candidates/create/PanelFormAndResume.vue';
import { nextTick } from 'vue';

export default {
  name: 'CreateCandidatesFromResume',
  components: {
    PanelFormAndResume,
    SendCandidates,
    FormResumeItem,
    Preloader,
    HelpInfo,
  },
  mixins: [dropFiles],
  data() {
    return {
      activeTab: 0,
      items: [],
      preloader: null,
      saveButtonPreloader: null,
      chain: Promise.resolve(),
      loadingInfo: {
        lengthAll: null,
        lengthLoaded: null,
        datetimeStart: null,
        datetimeLast: null,
      },
      itemsInTabReady: [],
      itemsInTabNotReady: [],
      itemsInTabError: [],
      modalMatchingPhoneItems: null,
      vacancy: '',
      vacanciesList: [],
      panelItem: null,
      savingCandidate: false
    };
  },
  methods: {
    changeStatusItem(item) {
      if (item.status === 'error') {
        throw Error('the current status of the item "error" is invalid for this method, you need to fix the error');
      }
      if (!item.form.data.resume.url) {
        item.status = 'loading';
        return;
      }
      item.status = item.form.checkForm() ? 'ready' : 'not_ready';
    },
    dropResumeFiles(event) {
      this.drop(event)
        .then(this.addSelectedFiles);
    },
    selectResumeFiles(input_file) {
      const inputFile = document.getElementById(input_file);
      inputFile.click();
    },
    onChangeInput(e) {
      if (e.target.files.length) {
        this.addSelectedFiles(e.target.files, true);
      }
    },
    processItemFile(item) {
      item.status = 'loading';
      this.chain = this.chain.then(() => this.$server.uploadFile(item.file, {
        parse: 1,
        type: 'candidate',
      }, { preventDefaultErrorHandler: true })
        .then((dataResponse) => {
          if (dataResponse.success) {
            item.form = new CandidateForm().applyResultParseResume(dataResponse.response);
            item.status = item.form.checkForm() ? 'ready' : 'not_ready';
          } else {
            item.status = 'error';
          }
        }))
        .catch(() => {
          item.status = 'error';
        })
        .finally(() => {
          this.loadingInfo.lengthLoaded += 1;
          this.loadingInfo.datetimeLast = Date.now();
          this.sortItemsByTabs();
          if (item.status !== 'ready' && this.activeTab === 0) {
            const tabName = item.status;
            this.activeTab = tabName === 'not_ready' ? 1 : 2;
          }
        });
    },
    startLoading() {
      this.sortItemsByTabs();
      if (this.items.find(({ status }) => status === 'loading')) {
        this.loadingInfo.lengthAll += this.items.filter(({ status }) => status === 'init').length;
      } else {
        this.loadingInfo.lengthAll = this.items.filter(({ status }) => status === 'init').length;
        this.loadingInfo.lengthLoaded = 0;
        this.loadingInfo.datetimeStart = Date.now();
      }
      this.items.filter(({ status }) => status === 'init')
        .forEach((item) => this.processItemFile(item));
    },
    addSelectedFiles(files) {
      files.forEach((file) => this.items.push({
        file,
        form: new CandidateForm(),
        status: 'init',
        event: {},
      }));
      this.startLoading();
    },
    sortItemsByTabs() {
      this.itemsInTabReady = [];
      this.itemsInTabNotReady = [];
      this.itemsInTabError = [];
      this.items.forEach((item) => {
        if (item.status === 'init' || item.status === 'loading' || item.status === 'ready') {
          this.itemsInTabReady.push(item);
          return;
        }
        if (item.status === 'not_ready') {
          this.itemsInTabNotReady.push(item);
          return;
        }
        if (item.status === 'error') {
          this.itemsInTabError.push(item);
          return;
        }
        throw Error(`Unknown status "${String(item.status)}"`);
      });
    },
    removeItem(item) {
      this.items = this.items.filter((_item) => _item !== item);
      this.sortItemsByTabs();
    },
    reUploadItem(item) {
      this.processItemFile(item);
    },
    getMatchingPhoneNumberForms() {
      const map = new Map();
      this.items.forEach((item) => {
        if (map.get(item.form.data.phone)) {
          map.get(item.form.data.phone)
            .push(item);
        } else {
          map.set(item.form.data.phone, [item]);
        }
      });
      return Array.from(map, ([phone, items]) => ({
        phone,
        items,
      }))
        .filter(({
          phone,
          items,
        }) => items.length > 1 && phone !== this.convertPhoneStr('70000000000'));
    },
    startSubmit() {
      this.saveButtonPreloader = true;
    },
    endSubmit() {
      this.saveButtonPreloader = false;
      this.items = [];
      this.$store.dispatch('sortCandidates');
      console.log('end submit');
      if (this.$route.query.v) {
        this.$router.push({ path: `/vacancies/vacancy/${this.$route.query.v}` });
        this.$eventBus.emit('create-from-vacancy-add-candidate');
      } else {
        this.$router.replace({ name: 'Candidates' });
      }
    },
    beforeSubmit() {
      if (this.isAllFormsReady) {
        this.savingCandidate = true;
        new Promise((resolve) => {
          if (!this.getMatchingPhoneNumberForms().length) {
            this.savingCandidate = false;
            resolve();
          } else {
            this.savingCandidate = false;
            this.modalMatchingPhoneItems = {
              matching: this.getMatchingPhoneNumberForms(),
              apply: () => {
                this.modalMatchingPhoneItems.matching.forEach((match) => {
                  match.items.forEach((item) => {
                    if (item !== match.selected) this.removeItem(item);
                  });
                });
                resolve();
                this.$refs['matching-phone-modal'].close();
              },
            };
            this.$nextTick(() => {
              this.$refs['matching-phone-modal'].open();
            });
          }
        }).then(() => {
          this.savingCandidate = false;
          if (this.vacancy) {
            this.items.forEach(({ form }) => {
              form.data.vacancy = this.vacancy;
            });
          }
          this.$refs['send-candidates'].submit();
        });
      }
    },
    openItemInPanel(item) {
      this.panelItem = item;
      nextTick(() => {
        this.$refs['panel-form'].open();
      });
    },
    loadVacanciesList() {
      return this.$server.request('vacancy/get', {
        limit: 100,
        status: 1,
      })
        .then(({ response }) => {
          this.vacanciesList = response.map((v) => ({
            value: v.id,
            text: v.title,
          }));
        });
    },
  },
  computed: {
    loadedItems() {
      return this.items.filter(({ status }) => status !== 'init' && status !== 'loading');
    },
    notLoadedItems() {
      return this.items.filter(({ status }) => status === 'init' || status === 'loading');
    },
    readyItems() {
      return this.items.filter(({ status }) => status === 'ready');
    },
    notReadyItems() {
      return this.items.filter(({ status }) => status === 'not_ready');
    },
    errorItems() {
      return this.items.filter(({ status }) => status === 'error');
    },
    loadProgress() {
      return Math.floor((this.loadingInfo.lengthLoaded / this.loadingInfo.lengthAll) * 100);
    },
    isAllFormsReady() {
      return this.items.every(({ status }) => status === 'ready');
    },
    isAllMatchingSelected() {
      return this.modalMatchingPhoneItems.matching.every(({ selected }) => selected);
    },
    timeLeft() {
      const current = this.loadingInfo.datetimeLast;
      const start = this.loadingInfo.datetimeStart;
      const oneItemDatetime = Math.floor((current - start) / this.loadingInfo.lengthLoaded);

      const {
        lengthAll,
        lengthLoaded,
      } = this.loadingInfo;
      const seconds = Math.floor(((lengthAll - lengthLoaded) * oneItemDatetime) / 1000);
      const minutes = Math.floor(seconds / 60);
      if (seconds === -Infinity) return '';
      return minutes ? `${minutes} мин` : `${seconds} сек`;
    },
    sendCandidatesForm() {
      return Array.from(this.readyItems, ({ form }) => form);
    },
    positionsCandidates() {
      const set = new Set();
      this.items.forEach((item) => set.add(item.form.data.position));
      return [...set].filter(Boolean);
    },
  },
  created() {
    this.loadVacanciesList();
    this.$eventBus.on('resume-from-parent', (files) => {
      this.addSelectedFiles(files);
    });
  },
  beforeDestroy() {
    this.$eventBus.off('resume-from-parent');
  }
};
</script>

<style lang="scss" scoped>

.page.half-width {
  --page-width: 664px;
}

.page-header {
  margin-bottom: 24px;
}

.create-candidates-help-info {
  margin-bottom: 38px;
}

.all-forms-length {
  color: rgba(0, 0, 0, 0.25);
}

.resume-load-progress {
  min-width: 140px;

  &_text {
    margin-bottom: 11px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;

    .icon {
      margin-left: 12px;
      width: 18px;
      height: 18px;
    }
  }
}

.load-area-block {
  padding: 48px;
  text-align: center;
  border: 1px dashed var(--page-color-main);
  border-radius: 8px;
  cursor: pointer;
}

.page-right-content {
  height: 100%;
  display: flex;
  flex-direction: column;

  .load-area-block {
    max-width: 244px;
  }

  .button-wrap {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tabs {
  margin-top: 24px;
}

.tab {
  padding-top: 58px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.select-vacancy {
  margin-bottom: 24px;
}

.matching-phone-modal {
  .modal-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .match-block {
    margin-top: 38px;
    margin-bottom: 38px;

    &_title {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    &_list {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      li {
        border-bottom: 1px solid var(--border-color);

        &:first-child {
          border-top: 1px solid var(--border-color);
        }
      }
    }

    &_item {
      display: flex;
      align-items: center;
      padding: 12px 0;

      &_inner {
        width: 100%;
        display: flex;
        align-items: center;
      }

      &_after {
        flex-shrink: 0;
        margin-left: 20px;
      }

      .icon-wrap {
        padding: 3px;
        background: #FFFFFF;
        box-shadow: 2px 4px 16px rgb(0 0 0 / 20%);
        border-radius: 6px;
      }

      .info-wrap {
        margin-left: 18px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > * {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .resume-size {
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: rgba(var(--page-color-main-rgb), .5);
      }

      .resume-file-name {
        font-size: 14px;
        line-height: 20px;
        color: var(--color-blue);
      }
    }
  }
}
</style>
